import React from 'react'

import Container from '~/components/Container'
import Content from '~/components/Content'
import Layout from '~/components/layouts'
import Markdown from '~/components/Markdown'

export default () => (
  <Layout>
    <Container>
      <Content>
        <Markdown
          dangerouslySetInnerHTML={{
            __html:
              '<h1>404</h1><h2>Document not found</h2><p><a href="/en">Return to homepage</a></p>',
          }}
        />
      </Content>
    </Container>
  </Layout>
)
